import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    em: "em",
    h2: "h2",
    h4: "h4",
    p: "p",
    pre: "pre",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["The goal of the data model is to ", _jsx(_components.em, {
        children: "cast types to what is expected, in order to prevent runtime errors due to external data sources out of our control"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Given this definition:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const Match = {\n  id: String,\n  data: {\n    players: [{}],\n  },\n};\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "And this data:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  id: 42,\n  data: null,\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "It is expected to return:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "{\n  id: \"42\",\n  data: {\n    players: [],\n  },\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "model-helpers",
      children: _jsx(_components.a, {
        href: "#model-helpers",
        children: "Model Helpers"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { Any, Silent, Optional, Partial } from \"@/__main__/data-model.mjs\";\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "anyunknown",
      children: _jsx(_components.a, {
        href: "#anyunknown",
        children: "Any/Unknown"
      })
    }), "\n", _jsx(_components.p, {
      children: "Allow any shape or value."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousShape: Any,\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "silent",
      children: _jsx(_components.a, {
        href: "#silent",
        children: "Silent"
      })
    }), "\n", _jsx(_components.p, {
      children: "This will cause properties that are missing to be initialized, and values of differing types to be cast to the expected type."
    }), "\n", _jsxs(_components.p, {
      children: ["This matches exactly with the default behavior except ", _jsx(_components.strong, {
        children: "but"
      }), " it will silence warnings on any type mismatch. This is particularly useful if certain fields are expected to be missing from the data source."]
    }), "\n", _jsxs(_components.p, {
      children: ["With ", _jsx(_components.code, {
        children: "dubiousValue = null"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousValue: Silent(Number),\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["you get ", _jsx(_components.code, {
        children: "{ dubiousValue = 0 }"
      })]
    }), "\n", _jsx(_components.h4, {
      id: "optional",
      children: _jsx(_components.a, {
        href: "#optional",
        children: "Optional"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Note: if the field is ", _jsx(_components.code, {
        children: "null"
      }), ", the key will be deleted instead of remaining ", _jsx(_components.code, {
        children: "null"
      }), ", however this can be overridden by using a ", _jsx(_components.code, {
        children: "Union"
      }), " with a ", _jsx(_components.code, {
        children: "null"
      }), " entry."]
    }), "\n", _jsxs(_components.p, {
      children: ["With ", _jsx(_components.code, {
        children: "dubiousValue = null"
      })]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousValue: Optional(Number),\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["you get a shape without a dubiousValue key ", _jsx(_components.code, {
        children: "{}"
      })]
    }), "\n", _jsx(_components.h4, {
      id: "partial--deeppartial",
      children: _jsx(_components.a, {
        href: "#partial--deeppartial",
        children: "Partial / DeepPartial"
      })
    }), "\n", _jsx(_components.p, {
      children: "Allow properties on a shape to not exist, but will enforce their values if the key exists. DeepPartial does this recursively."
    }), "\n", _jsx(_components.h4, {
      id: "union",
      children: _jsx(_components.a, {
        href: "#union",
        children: "Union"
      })
    }), "\n", _jsx(_components.p, {
      children: "Allow one of a set of values. These could be exact primitives, or other models, however only the first matched, valid model is used."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousValue: Union([shapeObj, Number]),\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "tuple",
      children: _jsx(_components.a, {
        href: "#tuple",
        children: "Tuple"
      })
    }), "\n", _jsx(_components.p, {
      children: "Denotes a fixed list of values."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousValue: Tuple([String, Number, shapeObj]),\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "mapped",
      children: _jsx(_components.a, {
        href: "#mapped",
        children: "Mapped"
      })
    }), "\n", _jsx(_components.p, {
      children: "Mark a field as being a mapping of arbitrary keys to values."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "dubiousMap: Mapped({ key: String, value: shapeObj }),\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "mapping-models-aftertransformmodel",
      children: _jsx(_components.a, {
        href: "#mapping-models-aftertransformmodel",
        children: "Mapping Models (afterTransformModel)"
      })
    }), "\n", _jsx(_components.p, {
      children: "The shape defined by BE is not necessarily the shape your data has to end in."
    }), "\n", _jsx(_components.p, {
      children: "For example: BE offers an list of all events I have opted into:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "export const model = {\n  id: String,\n  optIn: {\n    userAccountId: String,\n  },\n};\n\nconst apiValidationModel = createModel({ data: { events: [model] } });\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Scanning through the list to tell if a user is opted in every time is tedious. Getting ", _jsx(_components.code, {
        children: "userAccountId"
      }), " by using ", _jsx(_components.code, {
        children: "userOptIn.optIn.userAccountId"
      }), " is tedious. Lets fix that:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const afterTransformModel = {\n  id: String,\n  userAccountId: String,\n};\n\nconst afterTransformValidation = createModel(\n  Mapped({ key: String, value: afterTransformModel }),\n);\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Our new shape is set and (important) we can test/validate our mapping:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const mapOptIns = (events) =>\n  optIns.reduce((acc, e) => {\n    acc[e.id] = { userAccountId: e.userAccountId };\n    return acc;\n  }, {});\n\nfunction transform(data) {\n  data = apiValidationModel(data);\n  return afterTransformValidation(mapOptIns(data.data.events));\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "And finally you have a map of eventId to optIn info!"
    }), "\n", _jsx(_components.h2, {
      id: "why-not-popular-schema-validation-library",
      children: _jsx(_components.a, {
        href: "#why-not-popular-schema-validation-library",
        children: "Why not [popular schema validation library]?"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["A few have been evaluated, most notably ", _jsx(_components.a, {
        href: "https://github.com/colinhacks/zod",
        children: "Zod"
      }), ". However, by default it doesn't fulfill what we need it to do. Most libraries will default to throwing errors on type mismatch."]
    }), "\n", _jsx(_components.p, {
      children: "Therefore, even if we were to use a third-party library for this, we can't expose it directly because default usage is not suitable for our use case. Using a schema validation library in the way that it is intended may cause non-fatal errors to appear to the user as the app crashing, or at least make error recovery more difficult, since in most cases it would be possible to just typecast and continue."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
